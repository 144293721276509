import { css } from '@emotion/core';

export const quote = css`
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
`;

export const textWrapper = css`
  margin-bottom: 1rem;
`;
