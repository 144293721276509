import { css } from '@emotion/core';

export const wrapper = css`
  position: relative;
`;

export const image = css`
  border-radius: 4px;
`;

export const title = css`
  display: inline;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  width: 63%;
  max-width: 320px;
`;
