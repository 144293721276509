import React from 'react';

import Layout from '../global/Layout';
import Tiles from './Tiles';
import Testimonials from './Testimonials';

const Entry = () => {
  return (
    <Layout>
      <Tiles />
      <section class="hero has-text-centered is-light">
        <div class="hero-body">
          <div class="container">
            <h2 class="title is-4">Our Customers Love Us</h2>
          </div>
        </div>
      </section>
      <Testimonials />
    </Layout>
  );
};

export default Entry;
