import React from 'react';
import PropTypes from 'prop-types';
import { GoQuote } from 'react-icons/go';

import * as styles from './Testimonial.style';

const propTypes = {
  customer: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
};

const Testimonial = ({ customer, review }) => (
  <div className="notification">
    <p css={styles.textWrapper}>{review}</p>
    <p className="title is-6 is-marginless">{customer}</p>
    <GoQuote css={styles.quote} size="1.5rem" />
  </div>
);

Testimonial.propTypes = propTypes;

export default Testimonial;
