import React from 'react';

import SectionContainer from '../global/SectionContainer';
import Testimonial from './Testimonial';

import * as styles from './Testimonials.style';

const Testimonials = () => (
  <>
    <SectionContainer>
      <div css={styles.wrapper}>
        <div className="columns">
          <div className="column">
            <Testimonial
              customer="Chris M."
              review="If you want it, they have it.  This place has absolutely everything.  It is the largest store of its kind in the area, even bigger than bevmo.  Their beer selection is vast and I am always surprised when I intentionally look for something impossible to find and they have it.  If they don’t have it, the owner is open to getting it for you."
            />
          </div>
          <div className="column">
            <Testimonial
              customer="Hans H."
              review="An excellent selection of micro/craft brews, including some very hard-to-find ones. Here’s hoping they can score some Pliny the Younger someday!"
            />
          </div>
        </div>
      </div>
    </SectionContainer>
    <SectionContainer>
      <div css={styles.wrapper}>
        <div className="columns">
          <div className="column">
            <Testimonial
              customer="Debbie S."
              review="I can speak only as a mailorder/web customer, but I am really really happy with this business.  Ever since my state privatized liquor sales, I have been unable to find my favorite rum locally, and the few places I found nationally either wouldn’t ship out of their state or had incredibly high shipping costs.  Not only did MLW have it for the best price I’ve ever seen, but buying multiple bottles left the shipping price per bottle completely reasonable (and total price per bottle far less than I ever paid locally).  Placed my order, and the box arrived, beautifully packed and all bottles unbroken, in a very reasonable amount of time.  This will always be my go-to place for liquor I can’t find locally."
            />
          </div>
        </div>
      </div>
    </SectionContainer>
  </>
);

export default Testimonials;
