import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { ClassNames } from '@emotion/core';

import * as styles from './Tile.style';

const Tile = ({ image, linkTo, title, extraClass }) => {
  return (
    <ClassNames>
      {({ cx }) => (
        <Link
          className={cx('tile is-parent', extraClass)}
          css={styles.wrapper}
          to={linkTo}
        >
          <Img className="tile is-child" css={styles.image} fluid={image} />
          <h4 className="title has-text-white" css={styles.title}>
            {title}
          </h4>
        </Link>
      )}
    </ClassNames>
  );
};

export default Tile;
