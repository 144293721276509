import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SectionContainer from '../global/SectionContainer';
import Tile from './Tile';

import { getRouteDefinitions } from '../../routes';

const routes = getRouteDefinitions();

const Tiles = () => {
  const data = useStaticQuery(graphql`
    query {
      kegs: file(relativePath: { eq: "kegs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 432) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      drink: file(relativePath: { eq: "drink.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 432) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pouringWine: file(relativePath: { eq: "pouring-wine.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 432) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopDrink: file(relativePath: { eq: "drink-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 888) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopCheers: file(relativePath: { eq: "cheers-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 432) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileCheers: file(relativePath: { eq: "cheers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 432) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <SectionContainer>
      <div className="tile is-ancestor">
        <div className="tile is-8 is-vertical">
          <div className="tile">
            <Tile
              image={data.kegs.childImageSharp.fluid}
              linkTo={routes.kegInfo}
              title="Keg Info"
            />
            <Tile
              image={data.pouringWine.childImageSharp.fluid}
              linkTo={routes.tastings}
              title="Free Tastings"
            />
          </div>
          <Tile
            extraClass="is-hidden-mobile"
            image={data.desktopDrink.childImageSharp.fluid}
            linkTo={routes.ad}
            title="Latest Ad"
          />
          <Tile
            extraClass="is-hidden-tablet"
            image={data.drink.childImageSharp.fluid}
            linkTo={routes.ad}
            title="Latest Ad"
          />
        </div>
        <Tile
          extraClass="is-hidden-mobile"
          image={data.desktopCheers.childImageSharp.fluid}
          linkTo={routes.contact}
          title="Contact Us"
        />
        <Tile
          extraClass="is-hidden-tablet"
          image={data.mobileCheers.childImageSharp.fluid}
          linkTo={routes.contact}
          title="Contact Us"
        />
      </div>
    </SectionContainer>
  );
};

export default Tiles;
